.f24 .flag{display:inline-block;height:18px;width:24px;border-radius:4px;vertical-align:middle;line-height:24px;background:url(../img/flags24.png) no-repeat;border: 1px solid #cccccc}
.f24 ._African_Union{background-position: -1px  -24px;}
.f24 ._Arab_League{background-position: -1px  -48px;}
.f24 ._ASEAN{background-position: -1px  -72px;}
.f24 ._CARICOM{background-position: -1px  -96px;}
.f24 ._CIS{background-position: -1px  -120px;}
.f24 ._Commonwealth{background-position: -1px  -144px;}
.f24 ._England, .f24 .gbp{background-position: -1px  -168px;}
.f24 ._European_Union, .f24 .eu, .f24 .eur{background-position: -1px  -192px;}
.f24 ._Islamic_Conference{background-position: -1px  -216px;}
.f24 ._Kosovo{background-position: -1px  -240px;}
.f24 ._NATO{background-position: -1px  -264px;}
.f24 ._Northern_Cyprus{background-position: -1px  -288px;}
.f24 ._Northern_Ireland{background-position: -1px  -312px;}
.f24 ._Olimpic_Movement{background-position: -1px  -336px;}
.f24 ._OPEC{background-position: -1px  -360px;}
.f24 ._Red_Cross{background-position: -1px  -384px;}
.f24 ._Scotland{background-position: -1px  -408px;}
.f24 ._Somaliland{background-position: -1px  -432px;}
.f24 ._Tibet{background-position: -1px  -456px;}
.f24 ._United_Nations{background-position: -1px  -480px;}
.f24 ._Wales{background-position: -1px  -504px;}
.f24 .ad, .f24 .and {background-position: -1px -528px;}
.f24 .ae, .f24 .are, .f24 .aed, .f24 .aeb.lang, .f24 .ar.lang {background-position: -1px -552px;}
.f24 .af, .f24 .afg, .f24 .afn {background-position: -1px -576px;}
.f24 .ag, .f24 .atg, .f24 .xcd {background-position: -1px -600px;}
.f24 .ai, .f24 .aia, .f24 .xcd {background-position: -1px -624px;}
.f24 .al, .f24 .alb, .f24 .all, .f24 .sq.lang {background-position: -1px -648px;}
.f24 .am, .f24 .arm, .f24 .amd, .f24 .hy.lang {background-position: -1px -672px;}
.f24 .ao, .f24 .ago, .f24 .aoa {background-position: -1px -696px;}
.f24 .aq, .f24 .ata, .f24  {background-position: -1px -720px;}
.f24 .ar, .f24 .arg, .f24 .ars {background-position: -1px -744px;}
.f24 .as, .f24 .asm {background-position: -1px -768px;}
.f24 .at, .f24 .aut {background-position: -1px -792px;}
.f24 .au, .f24 .aus, .f24 .aud {background-position: -1px -816px;}
.f24 .aw, .f24 .abw, .f24 .awg {background-position: -1px -840px;}
.f24 .ax, .f24 .ala {background-position: -1px -864px;}
.f24 .az, .f24 .aze, .f24 .azn {background-position: -1px -888px;}
.f24 .ba, .f24 .bih, .f24 .bam, .f24 .bs.lang {background-position: -1px -912px;}
.f24 .bb, .f24 .brb, .f24 .bbd {background-position: -1px -936px;}
.f24 .bd, .f24 .bgd, .f24 .bdt, .f24 .bn.lang {background-position: -1px -960px;}
.f24 .be, .f24 .bel {background-position: -1px -984px;}
.f24 .bf, .f24 .bfa, .f24 .xof {background-position: -1px -1008px;}
.f24 .bg, .f24 .bgr, .f24 .bgn {background-position: -1px -1032px;}
.f24 .bh, .f24 .bhr, .f24 .bhd {background-position: -1px -1056px;}
.f24 .bi, .f24 .bdi, .f24 .bif {background-position: -1px -1080px;}
.f24 .bj, .f24 .ben, .f24 .xof {background-position: -1px -1104px;}
.f24 .bm, .f24 .bmu, .f24 .bmd {background-position: -1px -1128px;}
.f24 .bn, .f24 .brn, .f24 .bnd {background-position: -1px -1152px;}
.f24 .bo, .f24 .bol, .f24 .bov, .f24 .bob {background-position: -1px -1176px;}
.f24 .br, .f24 .bra, .f24 .brl, .f24 .br.lang {background-position: -1px -1200px;}
.f24 .bs, .f24 .bhs, .f24 .bsd {background-position: -1px -1224px;}
.f24 .bt, .f24 .btn, .f24 .inr {background-position: -1px -1248px;}
.f24 .bw, .f24 .bwa, .f24 .bwp {background-position: -1px -1272px;}
.f24 .by, .f24 .blr, .f24 .byn, .f24 .byr {background-position: -1px -1296px;}
.f24 .bz, .f24 .blz, .f24 .bzd {background-position: -1px -1320px;}
.f24 .ca, .f24 .can, .f24 .cad {background-position: -1px -1344px;}
.f24 .cd, .f24 .cod, .f24 .cdf {background-position: -1px -1368px;}
.f24 .cf, .f24 .rca, .f24 .xaf {background-position: -1px -1392px;}
.f24 .cg, .f24 .cog, .f24 .xaf {background-position: -1px -1416px;}
.f24 .ch, .f24 .che, .f24 .chf, .f24 .chw {background-position: -1px -1440px;}
.f24 .ci, .f24 .civ, .f24 .xof {background-position: -1px -1464px;}
.f24 .ck, .f24 .cok, .f24 .nzd {background-position: -1px -1488px;}
.f24 .cl, .f24 .chl, .f24 .clp, .f24 .clf {background-position: -1px -1512px;}
.f24 .cm, .f24 .cmr, .f24 .xaf {background-position: -1px -1536px;}
.f24 .cn, .f24 .chn, .f24 .cny, .f24 .zh.lang, .f24 .zh-cht.lang {background-position: -1px -1560px;}
.f24 .co, .f24 .col, .f24 .cop, .f24 .cou {background-position: -1px -1584px;}
.f24 .cr, .f24 .cri, .f24 .crc {background-position: -1px -1608px;}
.f24 .cu, .f24 .cub, .f24 .cup, .f24 .cuc {background-position: -1px -1632px;}
.f24 .cv, .f24 .cpv, .f24 .cve {background-position: -1px -1656px;}
.f24 .cy, .f24 .cyp {background-position: -1px -1680px;}
.f24 .cz, .f24 .cze, .f24 .czk, .f24 .cs.lang {background-position: -1px -1704px;}
.f24 .de, .f24 .deu, .f24 .de.lang {background-position: -1px -1728px;}
.f24 .dj, .f24 .dji, .f24 .djf {background-position: -1px -1752px;}
.f24 .dk, .f24 .dnk, .f24 .dkk, .f24 .da.lang {background-position: -1px -1776px;}
.f24 .dm, .f24 .dma, .f24 .xcd {background-position: -1px -1800px;}
.f24 .do, .f24 .dom, .f24 .dop {background-position: -1px -1824px;}
.f24 .dz, .f24 .dza, .f24 .dzd {background-position: -1px -1848px;}
.f24 .ec, .f24 .ecu, .f24 .xsu {background-position: -1px -1872px;}
.f24 .ee, .f24 .est {background-position: -1px -1896px;}
.f24 .eg, .f24 .egy, .f24 .egp {background-position: -1px -1920px;}
.f24 .eh, .f24 .esh, .f24 .mad {background-position: -1px -1944px;}
.f24 .er, .f24 .eri, .f24 .ern {background-position: -1px -1968px;}
.f24 .es, .f24 .esp, .f24 .es.lang {background-position: -1px -1992px;}
.f24 .et, .f24 .eth, .f24 .etb, .f24 .am.lang {background-position: -1px -2016px;}
.f24 .fi, .f24 .fin, .f24 .fi.lang {background-position: -1px -2040px;}
.f24 .fj, .f24 .fji, .f24 .fjd {background-position: -1px -2064px;}
.f24 .fm, .f24 .fsm {background-position: -1px -2088px;}
.f24 .fo, .f24 .fro, .f24 .dkk {background-position: -1px -2112px;}
.f24 .fr, .f24 .fra, .f24 .wf, .f24 .fr.lang {background-position: -1px -2136px;}
.f24 .bl, .f24 .blm {background-position: -1px -2136px;}
.f24 .cp, .f24 .cpt, .f24  {background-position: -1px -2136px;}
.f24 .mf, .f24 .maf {background-position: -1px -2136px;}
.f24 .yt, .f24 .myt {background-position: -1px -2136px;}
.f24 .ga, .f24 .gab, .f24 .xaf {background-position: -1px -2160px;}
.f24 .gb, .f24 .gbr, .f24 .en.lang {background-position: -1px -2184px;}
.f24 .sh, .f24 .shn, .f24 .shp {background-position: -1px -2184px;}
.f24 .gd, .f24 .grd, .f24 .xcd {background-position: -1px -2208px;}
.f24 .ge, .f24 .geo, .f24 .gel, .f24 .ka.lang {background-position: -1px -2232px;}
.f24 .gg, .f24 .ggy, .f24 .ggp {background-position: -1px -2256px;}
.f24 .gh, .f24 .gha, .f24 .ghs {background-position: -1px -2280px;}
.f24 .gi, .f24 .gib, .f24 .gip {background-position: -1px -2304px;}
.f24 .gl, .f24 .grl, .f24 .dkk {background-position: -1px -2328px;}
.f24 .gm, .f24 .gmb, .f24 .gmd {background-position: -1px -2352px;}
.f24 .gn, .f24 .gin, .f24 .gnf {background-position: -1px -2376px;}
.f24 .gp, .f24 .glp {background-position: -1px -2400px;}
.f24 .gq, .f24 .gnq, .f24 .xaf {background-position: -1px -2424px;}
.f24 .gr, .f24 .grc, .f24 .el.lang {background-position: -1px -2448px;}
.f24 .gt, .f24 .gtm, .f24 .gtq {background-position: -1px -2472px;}
.f24 .gu, .f24 .gum {background-position: -1px -2496px;}
.f24 .gw, .f24 .gnb, .f24 .xof {background-position: -1px -2520px;}
.f24 .gy, .f24 .guy, .f24 .gyd {background-position: -1px -2544px;}
.f24 .hk, .f24 .hkg, .f24 .hkd {background-position: -1px -2568px;}
.f24 .hn, .f24 .hnd, .f24 .hnl {background-position: -1px -2592px;}
.f24 .hr, .f24 .hrv, .f24 .hrk, .f24 .hr.lang {background-position: -1px -2616px;}
.f24 .ht, .f24 .hti, .f24 .htg {background-position: -1px -2640px;}
.f24 .hu, .f24 .hun, .f24 .huf {background-position: -1px -2664px;}
.f24 .id, .f24 .idn, .f24 .idr {background-position: -1px -2688px;}
.f24 .mc, .f24 .mco {background-position: -1px -2688px;}
.f24 .ie {background-position: -1px, -2712px;}
.f24 .il, .f24 .isr, .f24 .ils, .f24 .he.lang {background-position: -1px -2736px;}
.f24 .im, .f24 .imn, .f24 .imp {background-position: -1px -2760px;}
.f24 .in, .f24 .ind, .f24 .inr, .f24 .hi.lang, .f24 .ur-pk.lang {background-position: -1px -2784px;}
.f24 .iq, .f24 .irq, .f24 .iqd, .f24 .ku.lang {background-position: -1px -2808px;}
.f24 .ir, .f24 .irn, .f24 .irr, .f24 .irt, .f24 .fa.lang {background-position: -1px -2832px;}
.f24 .is, .f24 .isl, .f24 .isk {background-position: -1px -2856px;}
.f24 .it, .f24 .ita, .f24 .it.lang {background-position: -1px -2880px;}
.f24 .je, .f24 .jey, .f24 .jep {background-position: -1px -2904px;}
.f24 .jm, .f24 .jam, .f24 .jmd {background-position: -1px -2928px;}
.f24 .jo, .f24 .jor, .f24 .jod {background-position: -1px -2952px;}
.f24 .jp, .f24 .jpn, .f24 .jpy, .f24 .ja.lang {background-position: -1px -2976px;}
.f24 .ke, .f24 .ken, .f24 .kes {background-position: -1px -3000px;}
.f24 .kg, .f24 .kgz, .f24 .kgs {background-position: -1px -3024px;}
.f24 .kh, .f24 .khm, .f24 .khr {background-position: -1px -3048px;}
.f24 .ki, .f24 .kir, .f24 .aud {background-position: -1px -3072px;}
.f24 .km, .f24 .com, .f24 .kmf {background-position: -1px -3096px;}
.f24 .kn, .f24 .kna, .f24 .xcd {background-position: -1px -3120px;}
.f24 .kp, .f24 .prk, .f24 .kpw {background-position: -1px -3144px;}
.f24 .kr, .f24 .kor, .f24 .krw, .f24 .ko.lang {background-position: -1px -3168px;}
.f24 .kw, .f24 .kwt, .f24 .kwd {background-position: -1px -3192px;}
.f24 .ky, .f24 .cym, .f24 .kyd {background-position: -1px -3216px;}
.f24 .kz, .f24 .kaz, .f24 .kzt, .f24 .kk.lang {background-position: -1px -3240px;}
.f24 .la, .f24 .lao, .f24 .lak {background-position: -1px -3264px;}
.f24 .lb, .f24 .lbn, .f24 .lbp {background-position: -1px -3288px;}
.f24 .lc, .f24 .lca, .f24 .xcd {background-position: -1px -3312px;}
.f24 .li, .f24 .lie, .f24 .chf {background-position: -1px -3336px;}
.f24 .lk, .f24 .lka, .f24 .lkr, .f24 .si.lang {background-position: -1px -3360px;}
.f24 .lr, .f24 .lbr, .f24 .lrd {background-position: -1px -3384px;}
.f24 .ls, .f24 .lso, .f24 .lsl {background-position: -1px -3408px;}
.f24 .lt, .f24 .ltu {background-position: -1px -3432px;}
.f24 .lu, .f24 .lux {background-position: -1px -3456px;}
.f24 .lv, .f24 .lva {background-position: -1px -3480px;}
.f24 .ly, .f24 .lby, .f24 .lyd {background-position: -1px -3504px;}
.f24 .ma, .f24 .mar, .f24 .mad {background-position: -1px -3528px;}
.f24 .md, .f24 .mda, .f24 .mdl {background-position: -1px -3552px;}
.f24 .me, .f24 .mne, .f24 .cnr.lang {background-position: -1px -3576px;}
.f24 .mg, .f24 .mdg, .f24 .mga {background-position: -1px -3600px;}
.f24 .mh, .f24 .mhl {background-position: -1px -3624px;}
.f24 .mk, .f24 .mkd {background-position: -1px -3648px;}
.f24 .ml, .f24 .mli, .f24 .xof {background-position: -1px -3672px;}
.f24 .mm, .f24 .mmr, .f24 .mmk {background-position: -1px -3696px;}
.f24 .mn, .f24 .mng, .f24 .mnt {background-position: -1px -3720px;}
.f24 .mo, .f24 .mac, .f24 .mop {background-position: -1px -3744px;}
.f24 .mq, .f24 .mtq {background-position: -1px -3768px;}
.f24 .mr, .f24 .mrt, .f24 .mro, .f24 .mru {background-position: -1px -3792px;}
.f24 .ms, .f24 .msr, .f24 .xcd {background-position: -1px -3816px;}
.f24 .mt, .f24 .mlt {background-position: -1px -3840px;}
.f24 .mu, .f24 .mus, .f24 .mur {background-position: -1px -3864px;}
.f24 .mv, .f24 .mdv, .f24 .mvr {background-position: -1px -3888px;}
.f24 .mw, .f24 .mwi, .f24 .mwk {background-position: -1px -3912px;}
.f24 .mx, .f24 .mex, .f24 .mxn, .f24 .mxv {background-position: -1px -3936px;}
.f24 .my, .f24 .mys, .f24 .myr {background-position: -1px -3960px;}
.f24 .mz, .f24 .moz, .f24 .mzn {background-position: -1px -3984px;}
.f24 .na, .f24 .nam, .f24 .nad {background-position: -1px -4008px;}
.f24 .nc, .f24 .ncl, .f24 .xpf {background-position: -1px -4032px;}
.f24 .ne, .f24 .ner, .f24 .xof {background-position: -1px -4056px;}
.f24 .ng, .f24 .nga, .f24 .ngn {background-position: -1px -4080px;}
.f24 .ni, .f24 .nic, .f24 .nio {background-position: -1px -4104px;}
.f24 .nl, .f24 .nld {background-position: -1px -4128px;}
.f24 .no, .f24 .nor, .f24 .nok, .f24 .nb.lang {background-position: -1px -4152px;}
.f24 .bv, .f24 .bvt, .f24 .nok {background-position: -1px -4152px;}
.f24 .nq, .f24 .atn, .f24  {background-position: -1px -4152px;}
.f24 .sj, .f24 .sjm, .f24 .nok {background-position: -1px -4152px;}
.f24 .np, .f24 .npl, .f24 .npr, .f24 .ne.lang {background-position: -1px -4176px;}
.f24 .nr, .f24 .nru, .f24 .aud {background-position: -1px -4200px;}
.f24 .nz, .f24 .nzl, .f24 .nzd {background-position: -1px -4224px;}
.f24 .om, .f24 .omn, .f24 .omr {background-position: -1px -4248px;}
.f24 .pa, .f24 .pan, .f24 .pab {background-position: -1px -4272px;}
.f24 .pe, .f24 .per, .f24 .pen {background-position: -1px -4296px;}
.f24 .pf, .f24 .pyf, .f24 .xpf {background-position: -1px -4320px;}
.f24 .pg, .f24 .png, .f24 .pgk {background-position: -1px -4344px;}
.f24 .ph, .f24 .phl, .f24 .php {background-position: -1px -4368px;}
.f24 .pk, .f24 .pak, .f24 .pkr, .f24 .pa.lang {background-position: -1px -4392px;}
.f24 .pl, .f24 .pol, .f24 .pln {background-position: -1px -4416px;}
.f24 .pr, .f24 .pri {background-position: -1px -4440px;}
.f24 .ps, .f24 .pse, .f24 .jod {background-position: -1px -4464px;}
.f24 .pt, .f24 .prt, .f24 .pt.lang {background-position: -1px -4488px;}
.f24 .pw, .f24 .plw {background-position: -1px -4512px;}
.f24 .py, .f24 .pry, .f24 .pyg {background-position: -1px -4536px;}
.f24 .qa, .f24 .qat, .f24 .qar {background-position: -1px -4560px;}
.f24 .re, .f24 .reu {background-position: -1px -4584px;}
.f24 .ro, .f24 .rou, .f24 .ron {background-position: -1px -4608px;}
.f24 .rs, .f24 .srb, .f24 .rsd, .f24 .sr.lang {background-position: -1px -4632px;}
.f24 .ru, .f24 .rus, .f24 .rub, .f24 .ru.lang {background-position: -1px -4656px;}
.f24 .rw, .f24 .rwa, .f24 .rwf {background-position: -1px -4680px;}
.f24 .sa, .f24 .sau, .f24 .sar {background-position: -1px -4704px;}
.f24 .sb, .f24 .slb, .f24 .sbd {background-position: -1px -4728px;}
.f24 .sc, .f24 .syc, .f24 .scr {background-position: -1px -4752px;}
.f24 .sd, .f24 .sdn, .f24 .sdg {background-position: -1px -4776px;}
.f24 .se, .f24 .swe, .f24 .sek, .f24 .sv.lang {background-position: -1px -4800px;}
.f24 .sg, .f24 .sgp, .f24 .sgd {background-position: -1px -4824px;}
.f24 .si, .f24 .svn {background-position: -1px -4848px;}
.f24 .sk, .f24 .svk {background-position: -1px -4872px;}
.f24 .sl, .f24 .sle, .f24 .sll {background-position: -1px -4896px;}
.f24 .sm, .f24 .smr {background-position: -1px -4920px;}
.f24 .sn, .f24 .sen, .f24 .xof {background-position: -1px -4944px;}
.f24 .so, .f24 .som, .f24 .sos {background-position: -1px -4968px;}
.f24 .sr, .f24 .sur, .f24 .srd {background-position: -1px -4992px;}
.f24 .st, .f24 .stp, .f24 .std, .f24 .stn {background-position: -1px -5016px;}
.f24 .sv, .f24 .slv, .f24 .svc {background-position: -1px -5040px;}
.f24 .sy, .f24 .syr, .f24 .syp {background-position: -1px -5064px;}
.f24 .sz, .f24 .swz, .f24 .szl {background-position: -1px -5088px;}
.f24 .tc, .f24 .tca {background-position: -1px -5112px;}
.f24 .td, .f24 .tcd, .f24 .xaf {background-position: -1px -5136px;}
.f24 .tg, .f24 .tgo, .f24 .xof {background-position: -1px -5160px;}
.f24 .th, .f24 .tha, .f24 .thb, .f24 .th.lang {background-position: -1px -5184px;}
.f24 .tj, .f24 .tjk, .f24 .tjs {background-position: -1px -5208px;}
.f24 .tl, .f24 .tls {background-position: -1px -5232px;}
.f24 .tm, .f24 .tkm, .f24 .tmt {background-position: -1px -5256px;}
.f24 .tn, .f24 .tun, .f24 .tnd {background-position: -1px -5280px;}
.f24 .to, .f24 .ton, .f24 .top {background-position: -1px -5304px;}
.f24 .tr, .f24 .tur, .f24 .try, .f24 .tr.lang {background-position: -1px -5328px;}
.f24 .tt, .f24 .tto, .f24 .ttd {background-position: -1px -5352px;}
.f24 .tv, .f24 .tuv, .f24 .aud, .f24 .tvd {background-position: -1px -5376px;}
.f24 .tw, .f24 .twn, .f24 .twd {background-position: -1px -5400px;}
.f24 .tz, .f24 .tza, .f24 .tzs {background-position: -1px -5424px;}
.f24 .ua, .f24 .ukr, .f24 .uah, .f24 .uk.lang {background-position: -1px -5448px;}
.f24 .ug, .f24 .uga, .f24 .ugx {background-position: -1px -5472px;}
.f24 .uy, .f24 .ury, .f24 .uyu, .f24 .uyi {background-position: -1px -5520px;}
.f24 .uz, .f24 .uzb, .f24 .uzs {background-position: -1px -5544px;}
.f24 .va, .f24 .vat {background-position: -1px -5568px;}
.f24 .vc, .f24 .vct, .f24 .xcd {background-position: -1px -5592px;}
.f24 .ve, .f24 .ven, .f24 .vef {background-position: -1px -5616px;}
.f24 .vg, .f24 .vgb {background-position: -1px -5640px;}
.f24 .vi, .f24 .vir {background-position: -1px -5664px;}
.f24 .vn, .f24 .vnm, .f24 .vnd {background-position: -1px -5688px;}
.f24 .vu, .f24 .vut, .f24 .vuv {background-position: -1px -5712px;}
.f24 .ws, .f24 .wsm, .f24 .wst {background-position: -1px -5736px;}
.f24 .ye, .f24 .yem, .f24 .yer {background-position: -1px -5760px;}
.f24 .za, .f24 .zaf, .f24 .zar {background-position: -1px -5784px;}
.f24 .zw, .f24 .zwe, .f24 .zwl, .f24 .zwd {background-position: -1px -5832px;}
.f24 .us, .f24 .usa, .f24 .usd {background-position: -1px -5496px;}
.f24 .zm, .f24 .zmb, .f24 .zmw {background-position: -1px -5808px;}
.f24 .sx, .f24 .sxm, .f24 .ang {background-position: -1px -5856px;}
.f24 .cw, .f24 .cuw, .f24 .ang {background-position: -1px -5880px;}
.f24 .ss, .f24 .ssd, .f24 .ssp {background-position: -1px -5904px;}
.f24 .nu, .f24 .niu, .f24 .nzd {background-position: -1px -5928px;}
.f24 .fk, .f24 .fkp {background-position: -1px -5952px;}
.f24 .an {background-position: -1px -5976px;}
.f24 .io {background-position: -1px -6000px;}
.f24 .cx {background-position: -1px -6024px;}
.f24 .cc {background-position: -1px -6048px;}
.f24 .xk {background-position: -1px -6072px;}
.f24 .mp {background-position: -1px -6096px;}
.f24 .xua, .f24 .xdr, .f24 .xbtc, .f24 .ltc, .f24 .trx, .f24 .usn {background-position: -1px -6120px;}
.f24 .btc {background-position: -1px -6144px;}
.f24 .bch {background-position: -1px -6168px;}
.f24 .usdt {background-position: -1px -6192px;}
.f24 .eth {background-position: -1px -6216px;}
.f24 .ta, .f24 .tam {background-position: -1px -6240px;}
